// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  appVersion: require('../../package.json').version,
  production: true,
  environment: 'prod',

  apiBase: 'https://api.direct-collect.com.au',
  apiBaseAdmin: 'https://depotapi.direct-collect.com.au',
  Cognito: {
    domain:
      'direct-collect-prod-customer-domain.auth.ap-southeast-2.amazoncognito.com',
    userPoolId: 'ap-southeast-2_Lu2iEYgMh',
    userPoolWebClientId: '7a9hmo57ufc7sbc4tg0k9316bp',
    redirectSignIn: 'https://portal.direct-collect.com.au/user-home',
    redirectSignOut: 'https://portal.direct-collect.com.au/'
  },
  stripe_pk:
    'pk_live_51LZ2OoJWH8z1ad0RWqLJpaZ0Ovyysc7CNYBid4cR4KLIqSCowag0oByhu81rPRUjcEYooyw6iIGRi9VaKHwRPBa4004yRB5Mmc',
  maps_key: 'AIzaSyCoi9BscJgb32_p3ZoWlW5d1Ll6eaxGq4g'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
